import { TextField } from "@mui/material";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import "./ContactPage.css";

const apiKey = process.env.REACT_APP_API_KEY;

function ContactPage() {
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
    if (event.target.value.length !== 12) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
  };

  const handlePhoneNumber = (event) => {
    let value = event.target.value;
    value = value.replace(/\D/g, ""); // Remove all non-digit characters
    value = value.substring(0, 10); // Limit to first 10 digits
    if (value.length > 3 && value.length <= 6)
      value = value.slice(0, 3) + "-" + value.slice(3);
    else if (value.length > 6)
      value =
        value.slice(0, 3) + "-" + value.slice(3, 6) + "-" + value.slice(6);
    setPhone(value);
  };

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);

  const handleNameChange = (event) => {
    setName(event.target.value);
    if (event.target.value === "") {
      setNameError(true);
    } else {
      setNameError(false);
    }
  };

  const [model, setModel] = useState("");
  const [year, setYear] = useState("");
  const [trim, setTrim] = useState("");
  const [make, setMake] = useState("");
  const [message, setMessage] = useState("");

  const handleModelChange = (event) => {
    setModel(event.target.value);
  };

  const handleYearChange = (event) => {
    const re = /^[0-9\b]+$/; // Regular expression to match numbers and backspace

    // if value is not blank, then test the regex
    if (event.target.value === "" || re.test(event.target.value)) {
      setYear(event.target.value);
    }
  };

  const handleTrimChange = (event) => {
    setTrim(event.target.value);
  };

  const handleMakeChange = (event) => {
    setMake(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  /* This is where the submit button will send the data to the backend */

  const handleSubmit = async () => {
    const yearInt = parseInt(year, 10);

    const data = {
      name: name,
      phone: phone,
      make: make,
      model: model,
      year: yearInt,
      trim: trim,
    };

    const fullData = {
      name: name,
      phone: phone,
      make: make,
      model: model,
      year: yearInt,
      trim: trim,
      message: message,
    };

    setIsLoading(true);

    fetch("https://api.usautoglass.net/sendCustomer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apiKey,
      },
      body: JSON.stringify(fullData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    fetch("https://api.usautoglass.net/newCustomer", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apiKey,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        /* setIsLoading(false);
      setIsSuccess(true);
      setName('');
      setPhone('');
      setMake('');
      setModel('');
      setYear('');
      setTrim('');
      setMessage('');
      setTimeout(() => { setIsSuccess(false); }, 4000); */ // Lambda took too long to respond, so I had to comment this out
      })
      .catch((error) => {
        console.error("Error:", error);
        //setIsLoading(false);
        setName("");
        setPhone("");
        setMake("");
        setModel("");
        setYear("");
        setTrim("");
        setMessage("");
      });

    setTimeout(() => {
      setIsSuccess(true);
      setIsLoading(false);
      setName("");
      setPhone("");
      setMake("");
      setModel("");
      setYear("");
      setTrim("");
      setMessage("");
      setTimeout(() => {
        setIsSuccess(false);
      }, 4000);
    }, 2000);
  };

  return (
    <div style={{ marginTop: "60px" }}>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.3)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={80} />
        </div>
      )}
      {isSuccess && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.3)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Alert severity="success">
            {"Your message has been sent successfully!"}
          </Alert>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "5%",
          marginRight: "5%",
          gap: "13px",
          textAlign: "center",
        }}
      >
        <p className="text">
          We're here to help! Please provide your <strong>name</strong> and{" "}
          <strong>phone number</strong> in the form below.
          <br />
          <br />
          Additionally, any details about your car, and the nature of the glass
          damage would be greatly appreciated in the "Message" field.
          <br />
          <br />
          We'll get back to you ASAP!
        </p>

        <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
          <TextField
            id="nameField"
            label="Name"
            error={nameError}
            helperText={nameError ? "Name is required" : ""}
            value={name}
            onChange={handleNameChange}
            style={{ flex: 1 }}
          />

          <TextField
            id="phoneField"
            label="Phone #"
            inputProps={{
              maxLength: 12,
              pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}",
            }}
            error={phoneError}
            helperText={phoneError ? "Phone # must be 10 digits" : ""}
            value={phone}
            onChange={(event) => {
              handlePhoneChange(event);
              handlePhoneNumber(event);
            }}
            style={{ flex: 1 }}
          />
        </div>

        <div style={{ display: "flex", gap: "5px" }}>
          <TextField
            id="makeField"
            label="Make"
            value={make}
            onChange={handleMakeChange}
            style={{ flex: 1 }}
          />

          <TextField
            id="modelField"
            label="Model"
            value={model}
            onChange={handleModelChange}
            style={{ flex: 1 }}
          />

          <TextField
            id="yearField"
            label="Year"
            value={year}
            onChange={handleYearChange}
            inputProps={{ maxLength: 4 }}
            style={{ flex: 1 }}
          />

          <TextField
            id="trimField"
            label="Trim"
            value={trim}
            onChange={handleTrimChange}
            style={{ flex: 1 }}
          />
        </div>

        <TextField
          id="messageField"
          label="Message"
          value={message}
          onChange={handleMessageChange}
        />

        <Button
          variant="contained"
          disabled={nameError || phoneError || name === "" || phone === ""}
          onClick={handleSubmit}
          style={{ height: "40px" }}
        >
          {/* The '?' makes an if-else, so 'condition' ? ifTrue : ifFalse */}
          {nameError || phoneError || name === "" || phone === ""
            ? "Please fill out all name and phone"
            : "Submit"}
        </Button>
      </div>
    </div>
  );
}

export default ContactPage;
