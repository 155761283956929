import "./HomePage.css";
import React, { useState, useEffect } from "react";
import SouthIcon from "@mui/icons-material/South";
import SwiperComponent from "../components/SwiperComponent";
import SwiperComponentBA from "../components/SwiperComponentBA";

function HomePage() {
  const [scrolled, setScrolled] = useState(false);


  /* Add an event listener to the window object to listen for scroll events */
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 200) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div className={"welcome " + (scrolled ? "scrolled" : "")}>
        Welcome!
        <p>Scroll down to learn more about us</p>
        <p>Or request a quote now by clicking the top right button!</p>
        <div className={"arrow-down"}>
          <SouthIcon />
        </div>
      </div>

      <h1 className="header">About Us</h1>
      <div className="main-container">
        <div className="text-container">
          <p>
            {" "}
            Welcome to U.S. Auto Glass, your trusted family-owned auto glass
            experts for over 15 years serving the DC-Maryland-Virginia Area. We
            pride ourselves on delivering <strong>exceptional service</strong>,
            <strong> quality workmanship</strong>, and{" "}
            <strong>competitive pricing.</strong> Visit us for a reliable,
            friendly service you can count on!
          </p>
        </div>
        <img className="image" src="/Van2.jpg" alt="Description" />
      </div>

      <h1 className="header2">Our Services</h1>
      <div className="main-container2">
        <img className="image" src="/Service.png" alt="Description" />
        <div className="text-container">
          <p>
            Our services include <strong>windshield replacement</strong>,{" "}
            <strong>chip/crack repairs</strong>,{" "}
            <strong>side window replacements</strong>,{" "}
            <strong> rear window replacements</strong>,{" "}
            <strong>sunroof/moonroof replacements, </strong>
            and <strong>custom glass</strong> solutions. Using top materials and
            techniques, we ensure your vehicle's glass is safe and looks great.
          </p>
        </div>
      </div>
      <h1 className="header">
        Before & <span style={{ color: "#3f51b5" }}>A</span>fter
      </h1>
      <div className="main-container">
        <div className="text-container">
          <p>
            Check out our Before and After section to see the transformation of
            damaged auto glass to pristine condition. Our expert technicians
            handle everything from minor repairs to full replacements, ensuring
            your vehicle looks as good as new. Witness the quality and precision
            of our work through real examples of satisfied customers!
          </p>
          <p style={{ textAlign: "center" }}>
            <strong>Swipe</strong> to see more!
          </p>
        </div>
        <SwiperComponentBA />
      </div>

      <h1 className="header2">Reviews</h1>
      <div className="main-container2">
        <SwiperComponent />
        <div className="text-container">
          <p>
            {" "}
            We have received many great reviews from our customers in the past
            15 years of business, we strive to give the best service possible
            and make sure our customers are happy with the work we do.
          </p>
          <p style={{ textAlign: "center" }}>
            <strong>Swipe</strong> to see more!
          </p>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
