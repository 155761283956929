import { Swiper, SwiperSlide } from "swiper/react";
import React, { useState, useEffect } from "react";
import { Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css";

const SwiperComponentBA = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const calculateHeight = () => {
    // Adjust the multiplier as needed to get the desired height
    if (windowWidth <= 800) {
      return windowWidth * 0.6;
    }

    if (windowWidth >= 2000) {
      return windowWidth * 0.28;
    }

    return windowWidth * 0.31;
  };

  return (
    <Swiper
      modules={[Pagination]}
      style={{
        height: `${calculateHeight()}px`,
        marginTop: "30px",
        borderRadius: "8px",
        boxShadow: "0px 0px 10px 0px #000000",
        width: windowWidth <= 800 ? "100%" : "45%",
      }}
      pagination={true}
      spaceBetween={50}
      slidesPerView={1}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide>
        <img
          src="/Before1.JPG"
          alt="Slide 1"
          style={{ width: "100%", height: "100%" }}
        />
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            color: "red",
            fontSize: "24px",
            fontWeight: "bold",
            letterSpacing: "1px",
          }}
        >
          Before
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="/After1.JPG"
          alt="Slide 2"
          style={{ width: "100%", height: "100%" }}
        />
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            color: "blue",
            fontSize: "24px",
            fontWeight: "bold",
            letterSpacing: "1px",
          }}
        >
          After
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="/Before2.jpg"
          alt="Slide 3"
          style={{ width: "100%", height: "100%" }}
        />
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            color: "red",
            fontSize: "24px",
            fontWeight: "bold",
            letterSpacing: "1px",
          }}
        >
          Before
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="/After2.jpg"
          alt="Slide 1"
          style={{ width: "100%", height: "100%" }}
        />
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            color: "blue",
            fontSize: "24px",
            fontWeight: "bold",
            letterSpacing: "1px",
          }}
        >
          After
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="/Before3.JPG"
          alt="Slide 2"
          style={{ width: "100%", height: "100%" }}
        />
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            color: "red",
            fontSize: "24px",
            fontWeight: "bold",
            letterSpacing: "1px",
          }}
        >
          Before
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="/After3.JPG"
          alt="Slide 3"
          style={{ width: "100%", height: "100%" }}
        />
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            color: "blue",
            fontSize: "24px",
            fontWeight: "bold",
            letterSpacing: "1px",
          }}
        >
          After
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default SwiperComponentBA;
