import { Swiper, SwiperSlide } from "swiper/react";
import React, { useState, useEffect } from "react";
import { Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";

const SwiperComponent = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  /* Add an event listener to the window object to listen for resize events */
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  /* Take advantage of the window width to calculate the height of the Swiper component */
  const calculateHeight = () => {
    if (windowWidth <= 800) {
      return windowWidth * 0.6;
    }

    if (windowWidth >= 2000) {
      return windowWidth * 0.28;
    }

    return windowWidth * 0.3;
  };

  return (
    
    <Swiper
      modules={[Pagination]}
      style={{
        height: `${calculateHeight()}px`,
        marginTop: "30px",
        borderRadius: "8px",
        boxShadow: "0px 0px 10px 0px #000000",
        width: windowWidth <= 800 ? "100%" : "45%",
      }}
      pagination={true}
      spaceBetween={50}
      slidesPerView={1}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide>
        <img
          src="/R1.png"
          alt="Slide 1"
          style={{ width: "100%", height: "100%" }}
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="/R2.png"
          alt="Slide 2"
          style={{ width: "100%", height: "100%" }}
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="/R3.png"
          alt="Slide 3"
          style={{ width: "100%", height: "100%" }}
        />
      </SwiperSlide>
    </Swiper>
  );
};

export default SwiperComponent;
