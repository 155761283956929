import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import "./AppBarComponent.css";
import { useMediaQuery } from "@mui/material";

function AppBarComponent() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  /* You can extract styles to seperate object like here */
  const buttonStyles = {
    border: "2px solid #FFFFFF",
    fontSize: isSmallScreen ? "0.5rem" : "1rem",
    padding: isSmallScreen ? "4px 6px" : "8px 28px",
  };

  return (
    <React.Fragment>
      <AppBar className="AppBarComponent" position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            component={Link}
            to="/"
            aria-label="home"
          >
            <HomeIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Typography
            className="appbar-title"
            variant="h6"
            style={{
              textShadow: "1px 1px 1px #000000",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <span className="appbar-title-big">
              <span style={{ color: "red" }}>U.S.</span>
            </span>{" "}
            <span className="appbar-title-big"> Auto Glass</span>{" "}
            <span className="appbar-title-small">LLC.</span>
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            size={isSmallScreen ? "small" : "large"}
            variant="contained"
            component={Link}
            to="/contact"
            style={buttonStyles}
          >
            Request a Quote
          </Button>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </React.Fragment>
  );
}
export default AppBarComponent;
